import React, { Suspense, useMemo, useEffect, useState, useRef } from 'react';
import { useGLTF, OrbitControls, Sphere, Sky, Cloud, Html, Torus, Text } from '@react-three/drei';
import { Canvas, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Maximize2, Minimize2, RotateCcw } from 'lucide-react';
import { useSpring, animated } from '@react-spring/three';

function Model(props) {
  const { scene } = useGLTF('/models/apple_tree.glb');
  scene.traverse((child) => {
    if (child.isMesh) {
      child.castShadow = true;
      child.receiveShadow = true;
    }
  });
  return <primitive object={scene} {...props} />;
}

function Sun() {
  return (
    <Sphere args={[1.5, 32, 32]} position={[100, 10, 100]}>
      <meshStandardMaterial
        emissive="#FFFF00"
        emissiveIntensity={1}
        color="#FFFFFF"
      />
    </Sphere>
  );
}

function Snow({ color }) {
  const snowflakes = useMemo(() => {
    const temp = [];
    for (let i = 0; i < 5000; i++) {
      temp.push({
        position: new THREE.Vector3(
          Math.random() * 20 - 10,
          Math.random() * 10 + 5,
          Math.random() * 20 - 10
        ),
        velocity: Math.random() * 0.02 + 0.01
      });
    }
    return temp;
  }, []);

  const snowGeometry = useMemo(() => new THREE.BufferGeometry().setFromPoints(snowflakes.map(s => s.position)), []);

  useFrame(() => {
    snowflakes.forEach(snowflake => {
      snowflake.position.y -= snowflake.velocity;
      if (snowflake.position.y < -2) {
        snowflake.position.y = 10;
      }
    });
    snowGeometry.setFromPoints(snowflakes.map(s => s.position));
  });

  return (
    <points geometry={snowGeometry}>
      <pointsMaterial color={color} size={0.08} sizeAttenuation />
    </points>
  );
}

function Clouds({ color }) {
  return (
    <>
      <Cloud position={[-5, 8, 0]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, 3.5]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, 2.5]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, 4.5]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, -22.5]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, -24.5]} args={[3, 2]} color={color} />
      <Cloud position={[-19, 4, -26.5]} args={[3, 2]} color={color} />
      <Cloud position={[-5, 8, 3]} args={[3, 2]} color={color} />
      <Cloud position={[-5, 8, 5]} args={[3, 2]} color={color} />
      <Cloud position={[15, 7, 10]} args={[3.5, 2.5]} color={color} />
      <Cloud position={[-10, 12, -15]} args={[5, 3]} color={color} />
      <Cloud position={[0, 9, 15]} args={[3, 2.5]} color={color} />
      <Cloud position={[-15, 11, 20]} args={[3.5, 3]} color={color} />
      <Snow color={color} />
    </>
  );
}

function Rainbow() {
  const colors = [
    '#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'
  ];
  
  const rainbowGeometry = useMemo(() => {
    const geometry = new THREE.BufferGeometry();
    const positions = [];
    const indices = [];
    const vertexColors = [];
    const opacities = [];

    for (let i = 0; i < 50; i++) {
      const t = i / 49;
      const opacity = Math.sin(t * Math.PI) * Math.sin(t * Math.PI/4); // Modified opacity calculation
      for (let j = 0; j < colors.length; j++) {
        const angle = t * Math.PI;
        const x = Math.cos(angle) * (12 + j * 0.5);
        const y = Math.sin(angle) * (12 + j * 0.5);
        positions.push(x, y, 0);
        vertexColors.push(...new THREE.Color(colors[j]).toArray());
        opacities.push(opacity);

        if (i < 49 && j < colors.length - 1) {
          const currentIndex = i * colors.length + j;
          indices.push(
            currentIndex, currentIndex + 1, currentIndex + colors.length,
            currentIndex + 1, currentIndex + colors.length + 1, currentIndex + colors.length
          );
        }
      }
    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(vertexColors, 3));
    geometry.setAttribute('opacity', new THREE.Float32BufferAttribute(opacities, 1));
    geometry.setIndex(indices);

    return geometry;
  }, []);

  return (
    <group position={[-20, 4, -10]} rotation={[0, Math.PI / 2, 0]}>
      <mesh geometry={rainbowGeometry}>
        <shaderMaterial
          vertexShader={`
            attribute float opacity;
            varying vec3 vColor;
            varying float vOpacity;
            void main() {
              vColor = color;
              vOpacity = opacity;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `}
          fragmentShader={`
            varying vec3 vColor;
            varying float vOpacity;
            void main() {
              gl_FragColor = vec4(vColor, vOpacity * 0.3);
            }
          `}
          vertexColors
          transparent
          side={THREE.DoubleSide}
        />
      </mesh>
    </group>
  );
}

function MagicRings({ onColorSelect }) {
  const colors = [
    'red',  // Red
    'blue',  // Blue
    'yellow',  // Yellow
    'green',  // Green
    'orange',  // Orange
    'purple',  // Purple
    '#ff13f0',  // Pink
    '#964B00',  // Brown
    'black',  // Black
    'white'   // White
  ]
  
  const [hoverStates, setHoverStates] = useState(colors.map(() => false));
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleHover = (index, isHovering) => {
    setHoverStates(prev => {
      const newStates = [...prev];
      newStates[index] = isHovering;
      return newStates;
    });
  };

  const handleClick = useMemo(() => {
    let timeoutId;
    let audio;
    return (index, color) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
      timeoutId = setTimeout(() => {
        setSelectedIndex(index);
        onColorSelect(color);
        // Play the color name audio
        if (color === '#964B00'){
          audio = new Audio(`${process.env.PUBLIC_URL}/audios/brown.mp3`);
        } 
        else if (color === '#ff13f0'){
          audio = new Audio(`${process.env.PUBLIC_URL}/audios/pink.mp3`);
        }
        else {
          audio = new Audio(`${process.env.PUBLIC_URL}/audios/${color}.mp3`);
        }
        audio.play().catch(error => {
          console.error("Error playing audio:", error);
        });
      }, 300); // Adjust this delay as needed
    };
  }, [onColorSelect]);

  const AnimatedTorus = animated(Torus);

  const springs = useMemo(() => colors.map((_, index) => 
    ({
      scale: selectedIndex === index ? [1.2, 1.2, 1.2] : [1, 1, 1],
      rotation: selectedIndex === index ? [0, Math.PI * 2, 0] : [0, Math.PI / 2, 0],
      config: { mass: 1, tension: 280, friction: 60 }
    })
  ), [selectedIndex]);

  return (
    <>
      <group position={[-0, 3, -16]}>
        {colors.map((color, index) => {
          const { scale, rotation } = springs[index];

          return (
            <group key={`right-${color}`}>
              <AnimatedTorus
                args={[0.5, 0.2, 16, 100]}
                position={[0, -(index - 3) * 2, 0]}
                rotation={rotation}
                scale={scale}
                onClick={() => handleClick(index, color)}
                onPointerOver={() => handleHover(index + colors.length, true)}
                onPointerOut={() => handleHover(index + colors.length, false)}
              >
                <meshStandardMaterial color={color} emissive={color} emissiveIntensity={hoverStates[index + colors.length] ? 1 : 0.5} />
              </AnimatedTorus>
              <Sphere
                args={[0.3, 32, 32]}
                position={[0, -(index - 3) * 2, 0]}
                onClick={() => handleClick(index, color)}
              >
                <meshPhongMaterial 
                  transparent={true}
                  opacity={0.0}
                />
              </Sphere>
              {hoverStates[index + colors.length] && (
                <pointLight position={[0, -(index - 3) * 2, 0]} distance={3} intensity={2} color={color} />
              )}
            </group>
          );
        })}
      </group>
    </>
  );
}

function RayCaster() {
  const { camera, scene } = useThree();
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  const handleClick = (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (intersects.length > 0) {
      const intersectedObject = intersects[0].object;
      console.log('Clicked element properties:');
      console.log('Name:', intersectedObject.name);
      console.log('Type:', intersectedObject.type);
      console.log('Tag:', intersectedObject.tag);
      console.log('UUID:', intersectedObject.uuid);
      console.log('Position:', intersectedObject.position);
      console.log('Rotation:', intersectedObject.rotation);
      console.log('Scale:', intersectedObject.scale);
      if (intersectedObject.material) {
        console.log('Material:', intersectedObject.material.type);
      }
      if (intersectedObject.geometry) {
        console.log('Geometry:', intersectedObject.geometry.type);
      }
      
      // Check if the intersected object is a cloud
      if (intersectedObject.parent && intersectedObject.parent.type === 'Group' && intersectedObject.parent.name === 'Cloud') {
        console.log('Clicked on a cloud!');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return null;
}

function CameraInfo() {
  const { camera } = useThree();
  const [cameraInfo, setCameraInfo] = useState({ position: [0, 0, 0], rotation: [0, 0, 0] });

  useFrame(() => {
    setCameraInfo({
      position: camera.position.toArray().map(v => v.toFixed(2)),
      rotation: camera.rotation.toArray().slice(0, 3).map(v => v.toFixed(2))
    });
  });

  return (
    <Html fullscreen>
      <div style={{ position: 'absolute', top: '10px', left: '10px', color: 'white', backgroundColor: 'rgba(0,0,0,0.5)', padding: '5px' }}>
        <div>Position: {cameraInfo.position.join(', ')}</div>
        <div>Rotation: {cameraInfo.rotation.join(', ')}</div>
      </div>
    </Html>
  );
}

function MagicalEchoWind({ color }) {
  const meshRef = useRef();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
    const timer = setTimeout(() => setIsActive(false), 2000);
    return () => clearTimeout(timer);
  }, [color]);

  useFrame((state, delta) => {
    if (meshRef.current && isActive) {
      meshRef.current.material.uniforms.uTime.value += delta;
    }
  });

  const shaderMaterial = useMemo(() => {
    return new THREE.ShaderMaterial({
      uniforms: {
        uColor: { value: new THREE.Color(color) },
        uTime: { value: 0 },
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 uColor;
        uniform float uTime;
        varying vec2 vUv;
        void main() {
          float wave = sin(vUv.x * 10.0 + uTime * 2.0) * 0.5 + 0.5;
          float fade = 1.0 - min(1.0, uTime / 2.0);
          gl_FragColor = vec4(uColor, wave * 0.3 * fade);
        }
      `,
      transparent: true,
      side: THREE.DoubleSide,
    });
  }, [color]);

  return isActive ? (
    <mesh ref={meshRef} position={[0, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
      <sphereGeometry args={[50, 32, 32]} />
      <primitive object={shaderMaterial} attach="material" />
    </mesh>
  ) : null;
}
function DecoratedTitle() {
  return (
    <group position={[8, -1, 8]}>
      <Text
        position={[0, 0, 2]}
        rotation={[0, Math.PI / 2, 0]}
        fontSize={1}
        color="#FF69B4"
        anchorX="left"
        anchorY="bottom"
        font="/fonts/RubikBubbles-Regular.ttf"
      >
        Color
      </Text>
      <Text
        position={[0, -1.6, 1]}
        rotation={[0, Math.PI / 2, 0]}
        fontSize={1}
        color="#1E90FF"
        anchorX="left"
        anchorY="bottom"
        font="/fonts/RubikBubbles-Regular.ttf"
      >
        Clouds
      </Text>
      <Sphere args={[0.2, 16, 16]} position={[1.2, 0, 0.2]}>
        <meshBasicMaterial color="#FFD700" />
      </Sphere>
      <Sphere args={[0.15, 16, 16]} position={[1.2, -1.2, 0.2]}>
        <meshBasicMaterial color="#FF6347" />
      </Sphere>
      <Sphere args={[0.25, 16, 16]} position={[-1, -2.4, 0.2]}>
        <meshBasicMaterial color="#9400D3" />
      </Sphere>
    </group>
  );
}

export function ColorClouds() {
  const [selectedColor, setSelectedColor] = useState('#FFFFFF');
  const [isFullscreen, setIsFullscreen] = useState(true);
  const orbitControlsRef = useRef();

  useEffect(() => {
    const cursor = document.createElement('img');
    cursor.src = '/images/cursor.png';
    cursor.style.position = 'fixed';
    cursor.style.pointerEvents = 'none';
    cursor.style.zIndex = '9999';
    cursor.style.width = '64px';
    cursor.style.height = '64px';
    document.body.appendChild(cursor);

    const updateCursorPosition = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    };

    document.addEventListener('mousemove', updateCursorPosition);

    // Launch fullscreen by default
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }

    // Change to landscape mode if on mobile
    if (window.screen && window.screen.orientation && window.screen.orientation.lock) {
      window.screen.orientation.lock('landscape').catch(err => {
        console.error(`Error attempting to lock screen orientation: ${err.message} (${err.name})`);
      });
    }

    return () => {
      document.removeEventListener('mousemove', updateCursorPosition);
      document.body.removeChild(cursor);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
      if (orbitControlsRef.current) {
        orbitControlsRef.current.object.position.set(19, 0, 0);
        orbitControlsRef.current.update();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const resetView = () => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.reset();
      orbitControlsRef.current.object.position.set(19, 0, 0);
      orbitControlsRef.current.update();
    }
  };

  // Adjust light color based on selectedColor
  const lightColor = new THREE.Color(selectedColor).lerp(new THREE.Color('#FFF5E6'), 0.7);

  return (
    <>
      <Canvas
        camera={{ position: [19, 0, 0], fov: 60}}
        shadows
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'none' }}
      >
        <Sky sunPosition={[100, 10, 100]} />
        <ambientLight intensity={0.5} color={lightColor} />
        <directionalLight
          position={[10, 10, 0]}
          intensity={4}
          color={lightColor}
          castShadow
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        <Suspense fallback={null}>
          <Model position={[0, -6, 0]} scale={[0.9, 0.9, 0.9]} />
          <Sun />
          <Clouds color={selectedColor} />
          <Rainbow />
          <MagicRings onColorSelect={setSelectedColor} />
          <DecoratedTitle />
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -6, 0]} receiveShadow>
          <planeGeometry args={[200, 200]} />
          <shadowMaterial opacity={0.5} />
        </mesh>
        </Suspense>
        <OrbitControls 
          ref={orbitControlsRef}
          minDistance={5} 
          maxDistance={19}
          enablePan={false}
          maxPolarAngle={Math.PI / 2}
        />
        <RayCaster />
        {/* <CameraInfo /> */}
      </Canvas>
      <div style={{ position: 'absolute', bottom: '20px', left: '20px', zIndex: 1000 }}>
        <button onClick={toggleFullscreen} style={{ marginRight: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
          {isFullscreen ? <Minimize2 size={24} color="white" /> : <Maximize2 size={24} color="white" />}
        </button>
        <button onClick={resetView} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
          <RotateCcw size={24} color="white" />
        </button>
      </div>
    </>
  );
}